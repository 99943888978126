import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login'),
  },
  {
    path: '/reservation',
    name: 'Reservation',
    component: () => import('../views/Reservation'),
    meta: { requiresAuth: true },
  },
  {
    path: '/workshift',
    name: 'WorkShift',
    component: () => import('../views/WorkShift'),
    meta: { requiresAuth: true, adminOnly: true },
  },
  {
    path: '/entry',
    name: 'Entry',
    component: () => import('../views/Entry'),
    meta: { requiresAuth: true },
  },
  {
    path: '/trialentry',
    name: 'TrialEntry',
    component: () => import('../views/TrialEntry'),
  },
  {
    path: '/singup',
    name: 'Singup',
    component: () => import('../views/Singup'),
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('../views/Shop'),
    meta: { requiresAuth: true, adminOnly: true },
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import('../views/Member'),
    meta: { requiresAuth: true, adminOnly: true },
  },
  {
    path: '/instructor',
    name: 'Instructor',
    component: () => import('../views/Instructor'),
    meta: { requiresAuth: true, adminOnly: true },
  },
  // {
  //   path: '/staff',
  //   name: 'Staff',
  //   component: () => import('../views/Staff'),
  //   meta: { requiresAuth: true },
  // },
  {
    path: '/requeststatus',
    name: 'RequestStatus',
    component: () => import('../views/RequestStatus'),
    meta: { requiresAuth: true },
  },
  {
    path: '/attendancehistory',
    name: 'AttendanceHistory',
    component: () => import('../views/AttendanceHistory'),
    meta: { requiresAuth: true },
  },
  // {
  //   path: '/lessonhistory',
  //   name: 'LessonHistory',
  //   component: () => import('../views/LessonHistory'),
  //   meta: { requiresAuth: true },
  // },
  {
    path: '/notification',
    name: 'Notification',
    component: () => import('../views/Notification'),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name === null) {
    next('/login');
    return;
  }
  if (to.path === '/login') {
    next();
    return;
  }
  const requiresAuth = to.matched.some(recode => recode.meta.requiresAuth);
  const adminOnly = to.matched.some(recode => recode.meta.adminOnly);
  const isLogin = await store.dispatch('reLogin');
  const roll = store.getters.user.roll;
  if (requiresAuth && !isLogin) {
    next('/login');
  } else if (adminOnly && roll !== 'admin') {
    next('/login');
  } else {
    next();
  }
});

export default router;
