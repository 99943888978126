import { getApp, initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = document.domain === 'localhost';
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LfTL90dAAAAANSJdhGbW-nhVR7H-j0wOQCYsxf2'),
  isTokenAutoRefreshEnabled: true,
});

const functions = getFunctions(getApp(), 'asia-northeast1');

export default {
  async callCloudFunction(funcName, param = {}) {
    return new Promise((resolve, reject) => {
      let func = '';
      if (process.env.NODE_ENV === 'development') {
        // local開発
        func = connectFunctionsEmulator(functions, 'localhost', 5001);
      }
      func = httpsCallable(functions, funcName);
      return func(param).then(result => {
        const data = result.data;
        resolve(data);
      }).catch(error => {
        const message = error.message;
        reject(message);
      });
    });
  },
  zeroPadding(num) {
    return (Array(2).join('0') + num).slice(-2);
  },
  timeList(start, end) {
    const list = [];
    for (let i = start; i <= end; i += 1) {
      list.push(`${this.zeroPadding(i)}:00`);
    }
    return list;
  },
  initializeFireStore() {
    return getFirestore(getApp());
  },
};
