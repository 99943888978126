<template>
  <v-app>
    <v-app-bar
      v-if="$route.path.toLowerCase() !== '/singup' && $route.path.toLowerCase() !== '/login'"
      app
      color="#0673b2"
      clipped-left
      dense
      dark
    >
      <v-img
        max-height="190"
        max-width="170"
        src="./assets/strong-golf.png"
      ></v-img>
      <v-spacer></v-spacer>
      <v-menu
        transition="slide-y-transition"
        bottom
        offset-y
        dark
        v-if="$route.path.toLowerCase() !== '/trialentry' && userInfo.roll !== 'member'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#0673b2"
            dark
            depressed
            v-bind="attrs"
            v-on="on"
          >
            <span>{{ userInfo.name }}</span>
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense color="#0673b2">
          <v-list-item>
            <v-btn class="ma-0 pa-0" color="#0673b2" depressed @click="logout">ログアウト</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-app-bar-nav-icon v-if="$route.path.toLowerCase() !== '/trialentry'" @click.stop="drawer = !drawer"/>
    </v-app-bar>
    <!-- navigation-drawer -->
    <v-navigation-drawer
      v-if="$route.path.toLowerCase() !== '/singup' && $route.path.toLowerCase() !== '/login' && $route.path.toLowerCase() !== '/trialentry'"
      v-model="drawer"
      app
      color="#0673b2"
      clipped
      dark
      :expand-on-hover="hover"
      mini-variant-width="40"
      width="220"
      bottom
      :permanent="!$vuetify.breakpoint.mobile && drawer"
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group>
          <v-list-item
            v-for='(list, index) in naviByRoll'
            :key='index'
            :to='list.to'
          >
           <v-list-item-icon class="mx-2">
              <v-icon>{{ list.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-if="list.title !== 'ログアウト'" class="ml-2">{{ list.title }}</v-list-item-title>
            <v-list-item-title v-else class="ml-2" @click="logout">{{ list.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
    <!-- authorization warning -->
    <v-dialog
      v-model="expired"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title>ログインの有効期限が切れました</v-card-title>
        <v-card-text>
          <p>続行するには再度ログインが必要です。</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-2"
            dark
            style="min-width: 120px;"
            @click="login"
          >
            <v-icon>
              mdi-exit-to-app
            </v-icon>
            ログイン
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar.opened"
      :color="snackbar.color"
      text
    >
      {{ snackbar.message }}
      <v-btn
        outlined
        :color="snackbar.color"
        @click="$store.commit('snackbar/close')"
      >
        閉じる
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import snackbar from './store/snackbar';

export default {
  name: 'App',

  data: () => ({
    drawer: true,
    hover: false,
    navilist: {
      admin: [
        { title: '予約管理', to: '/reservation', icon: 'mdi-calendar' },
        { title: 'シフト管理', to: '/workshift', icon: 'mdi-clock-outline' },
        { title: '会員管理', to: '/member', icon: 'mdi-card-account-details-outline' },
        { title: 'インストラクター管理', to: '/instructor', icon: 'mdi-account-supervisor' },
        { title: '店舗管理', to: '/shop', icon: 'mdi-store' },
        { title: 'お知らせ管理', to: '/notification', icon: 'mdi-bell' },
      ],
      member: [
        { title: '予約申込み', to: '/entry', icon: 'mdi-calendar' },
        { title: '申込み状況', to: '/requeststatus', icon: 'mdi-golf' },
        { title: '利用履歴', to: '/attendancehistory', icon: 'mdi-history' },
        { title: 'ログアウト', to: '/logout', icon: 'mdi-logout' },
      ],
      instructor: [
        { title: '予約管理', to: '/reservation', icon: 'mdi-calendar' },
      ],
    },
  }),
  computed: {
    ...mapState({
      snackbar: state => state.snackbar || {
        opened: false,
        message: '',
      },
      expired: state => state.expired,
    }),
    loginUrl() {
      return '/login';
    },
    userEmail() {
      return this.$store.getters.userEmail;
    },
    userInfo() {
      return this.$store.getters.user;
    },
    naviByRoll() {
      return this.navilist[this.userInfo.roll];
    },
  },
  async created() {
    this.$store.registerModule('snackbar', snackbar);
  },
  methods: {
    login() {
      window.location.href = this.loginUrl;
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
};
</script>
<style lang="scss">
// .fade-enter-active {
//   transition: all 1s ease;
// }
// .fade-enter,
// .fade-leave-to {
//   opacity: 0;
//   transform: translateX(30px);
// }
// .v-main__wrap {
//   background-color: whitesmoke;
// }
.required-label {
  border-radius: 3px;
  color: white;
  font-size: 11px;
  font-weight: 600;
  margin-left: 2px;
  padding: 2px 4px;
  vertical-align: 2px;
  background-color: #cc0000;
}
</style>
