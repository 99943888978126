import { doc, getDoc } from 'firebase/firestore';
import config from '../config';

const db = config.initializeFireStore();

export class userFireStore {
  static getUserById(uid) {
    const docRef = doc(db, 'User', uid);
    return getDoc(docRef).then((res) => {
      if (!res.exists()) {
        return new Error('No such document!');
      }
      return res.data();
    });
  }
}
