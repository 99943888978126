import Vue from 'vue';
import Vuex from 'vuex';
import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence, onAuthStateChanged, signOut } from 'firebase/auth';
// import util from '../util';
import { userRepository } from '../domain/service/userRepository';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      shops: [],
    },
    isLogin: false,
    userEmail: '',
    userCreationTime: '',
  },
  mutations: {
    setUserInfo(state, user) {
      state.user = user;
      state.isLogin = true;
    },
    logout(state) {
      state.isLogin = false;
      state.userEmail = '';
    },
    setUserEmail(state, email) {
      state.userEmail = email;
    },
    setUserCreationTime(state, creationTime) {
      state.userCreationTime = creationTime;
    },
  },
  actions: {
    async singIn({ commit }, { email, password }) {
      const auth = getAuth();
      await setPersistence(auth, browserSessionPersistence);
      await signInWithEmailAndPassword(auth, email, password).then(async (res) => {
        // const userInfo = await util.callCloudFunction('getUserById', { id: res.user.uid });
        const userInfo = await userRepository.getUserById(res.user.uid);
        commit('setUserEmail', res.user.email);
        commit('setUserCreationTime', res.user.metadata.creationTime);
        commit('setUserInfo', userInfo);
      }).catch((error) => {
        console.log('error', error);
        throw new Error('login error');
      });
    },
    reLogin({ commit }) {
      return new Promise((resolve) => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            // const userInfo = await util.callCloudFunction('getUserById', { id: user.uid });
            const userInfo = await userRepository.getUserById(user.uid);
            commit('setUserInfo', userInfo);
            commit('setUserEmail', user.email);
            commit('setUserCreationTime', user.metadata.creationTime);
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },
    logout({ commit }) {
      const auth = getAuth();
      return signOut(auth)
        .then(() => {
          commit('logout');
        })
        .catch((error) => {
          console.log(`ログアウト時にエラーが発生しました (${error})`);
        });
    },
  },
  modules: {
  },
  getters: {
    user(state) {
      return state.user;
    },
    userEmail(state) {
      return state.userEmail;
    },
    userCreationTime(state) {
      return state.userCreationTime;
    },
    isLogin(state) {
      return state.isLogin;
    },
  },
});
