export default {
  namespaced: true,
  state() {
    return {
      message: '',
      opened: false,
    };
  },
  mutations: {
    open(state, message) {
      if (typeof message === 'object') {
        state.message = message.text;
        state.color = message.color || 'primary';
        state.opened = true;
      } else {
        state.message = message;
        state.color = 'primary';
        state.opened = true;
      }
    },
    close(state) {
      state.opened = false;
    },
  },
};
