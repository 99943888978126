import Vue from 'vue';
// import * as firebsae from 'firebase/app';
import App from './App';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

// const firebaseConfig = {
//   apiKey: 'AIzaSyAQSp4G9M392EzJkcppaNjU2xm3fWWR93c',
//   authDomain: 'dev-strong-golf.firebaseapp.com',
//   projectId: 'dev-strong-golf',
//   storageBucket: 'dev-strong-golf.appspot.com',
//   messagingSenderId: '383965749599',
//   appId: '1:383965749599:web:9a6936ca234acc6e2296ea',
// };

// firebsae.initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
